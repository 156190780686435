.floating-field {
  position: relative;

  label {
    position: absolute;
    //height: var(--default-input-height);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    pointer-events: none;
    transition: all .5s;
    margin: 0;
  }

  &._filled input + label,
  input:focus + label {
    top: 0;
  }

  &._filled {
    //color: red;
  }

  & > input[type="text"], & > input[type="password"], & > input[type="search"] {
    &:-internal-autofill-selected {
      transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
    }
  }
}

@include respond-up('medium') {
  .floating-field {

    &:not(:first-child) {
      margin-top: 38px;
    }

    label {
      font-size: 16px;
      top: 20px;
    }

    &._filled input + label,
    input:focus + label {
      font-size: 13px;
    }
  }
}

@include respond-down('small') {
  .floating-field {

    &:not(:first-child) {
      margin-top: 20px;
    }

    label {
      top: 14px;
      font-size: 20px;
    }

    &._filled input + label,
    input:focus + label {
      font-size: 12px;
    }
  }
}