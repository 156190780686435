.models {

}

@include respond-up('large') {
  .models {
    padding-top: 70px;
    grid-row-gap: 90px;
  }
}

@include respond('medium') {
  .models {
    padding-top: clamp(35px, calc(50 * 100vw / 1600), 50px);
    grid-row-gap: clamp(35px, calc(50 * 100vw / 1600), 50px);
  }
}

@include respond-down('small') {
  .models {
    padding-top: 40px;
    grid-row-gap: 40px;
  }
}