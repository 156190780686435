.model {
  &__wrapper {
    border-bottom: 1px solid var(--white-50);
  }

  &__info {
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }

  &__info,
  &__link,
  &__picture {
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__price {
    font-weight: 300;
    letter-spacing: 0em;
  }
}

@include respond-up('large') {
  .model {
    &__price {
      //styleName: Desktop/Цены;
      font-size: 20px;
      line-height: 24px;
    }

    &__picture {
      width: var(--grid-column6);
      height: calc(532 / 628 * var(--grid-column6));
    }

    &__wrapper {
      grid-column: span 6;
      padding-bottom: 14px;
      position: relative;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: -2px;
        width: 100%;
        height: 2px;
        background-color: var(--secondary-color);
        transform: scaleX(0);
        transform-origin: right;
        transition: transform .4s var(--default-bezier);
      }

      &:hover {
        &:before {
          transform-origin: left;
          transform: scaleX(1);
          transition: transform .4s var(--default-bezier);
        }
      }

      &:nth-child(odd) {
        margin-right: 12px;
      }

      &:nth-child(even) {
        margin-left: 12px;
      }
    }

    &__info {
      margin-top: 20px;
    }
  }
}

@include respond('medium') {
  .model {
    &__wrapper {
      grid-column: span 4;
      padding-bottom: 16px;
    }

    &__picture {
      width: var(--grid-column4);
      height: clamp(150px, calc(282 / 336 * var(--grid-column4)), 55vh);
    }

    &__info {
      margin-top: 16px;
    }
  }
}

@include respond('medium') {
  .model {
    &__price {
      //styleName: Tab/Цены ;
      font-size: 16px;
      line-height: 19px;
    }
  }
}

@include respond-down('small') {
  .model {
    &__wrapper {
      grid-column: span 4;
    }

    &__picture {
      width: var(--grid-column4);
      height: calc(286 / 338 * var(--grid-column4));
    }

    &__info {
      margin-top: 14px;
      padding-bottom: 14px;
    }
  }
}