.text {
  &__heading {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }

  &__main {
    //styleName: Дескриптер;
    font-family: var(--font);
    font-weight: 300;
    //заплата на хром, отображает 300 как 100
    -webkit-text-stroke: 0.1px;

    strong {
      font-weight: 400;
      -webkit-text-stroke: 0;
    }
  }

  &__price {
    //styleName: Цены;
    font-weight: 300;
    //заплата на хром, отображает 300 как 100
    -webkit-text-stroke: 0.1px;
  }

  &__sixteen {
    //styleName: Desktop/16;
    font-size: 16px;
    font-weight: 400;
  }

  &__small {
    //styleName: Мелкий;
    font-weight: 400;
    line-height: 130%;
  }

  &_blue {
    color: var(--dark-blue);
  }

  &_blue_70 {
    color: var(--dark-blue-70);
  }

  &_green {
    color: var(--secondary-color);
  }

  &_white {
    color: white;
  }

  &_white_70 {
    color: var(--white-70);
  }

  &_white_50 {
    color: var(--white-50);
  }
}

@include respond-up('large') {
  .text {
    &__heading {
      padding-bottom: 30px;
    }

    &__main {
      font-size: 18px;
      line-height: 23px;
    }

    &__price {
      font-size: 20px;
      line-height: 24px;
    }

    &__small {
      font-size: 14px;
    }
  }
}

@include respond('medium') {
  .text {
    &__heading {
      padding-bottom: 22px;
    }
  }
}

@include respond-down('medium') {
  .text {
    &__main {
      font-size: 16px;
      line-height: 21px;
    }

    &__small {
      font-size: 12px;
    }

    &__price {
      font-size: 16px;
      line-height: 19px;
    }
  }
}

@include respond-down('small') {
  .text {
    &__heading {
      padding-bottom: 17px;
    }
  }
}