.gift-block {
  display: flex;
  margin-left: calc(0px - var(--grid-spacer-and-indent));
  margin-right: calc(0px - var(--grid-spacer-and-indent));

  &__white {
    background: #fff;
  }

  .text__heading {
    border-color: var(--lines-color);
    max-width: 512px; //fun
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__image-block {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
}

@include respond-up('large') {
  .gift-block {
    max-height: 627px;

    &__white {
      flex: 0 0 50vw;
      padding: 98px 90px 98px var(--grid-spacer-and-indent);
    }

    &__description {
      margin-top: 30px;
      max-width: 405px;
    }

    &__button-wrap {
      margin-top: 80px;
    }

    &__image-block {
      flex: 0 0 50vw;
    }

    &__picture {
      height: 627px;
    }

    &__image {
      object-fit: cover;
    }
  }
}

@include respond('medium') {
  .gift-block {
    max-height: 459px;

    &__white {
      //flex: 0 0 51vw;
      flex: 0 0 calc(var(--grid-column4) + var(--grid-spacer) + var(--grid-gap));
      padding: 63px 20px 63px var(--grid-spacer);
    }

    &__description {
      margin-top: 22px;
      max-width: 279px;
    }

    &__button-wrap {
      margin-top: 50px;
    }

    &__image-block {
      //flex: 0 0 49vw;
      flex: 0 0 calc(var(--grid-column4) + var(--grid-spacer));
    }
  }
}

@include respond-down('small') {
  .gift-block {
    flex-direction: column-reverse;

    &__white {
      padding: 40px var(--grid-spacer) 60px var(--grid-spacer);
    }

    &__title {
      font-size: 34px;
    }

    &__description {
      margin-top: 20px;
    }

    &__button-wrap {
      margin-top: 40px;
      display: flex;
      justify-content: center;
    }

    &__image-block {
      width: 100vw;
      max-height: 288px;
    }

    &__picture {
      height: 288px;
    }
  }
}