//TODO: ничерта не правильно. там есть две формы. она в модалке, другая на страницк.
// и вот верстаются они по-разному. переписать надо
// модал можно оставить модал, она нормально рабоатет. а вот для страницы сделать другую

.modal-form {
  &__policy-wrapper
  {
    display: flex;
    align-items: center;
  }
}

@include respond-up('large') {
  .modal-form {
    &__wrap {
      padding: 80px 110px;
    }

    &__policy-text {
      margin-left: 70px;
      max-width: 225px;
    }
  }
}

@include respond-up('medium') {
  .modal-form {
    &__description {
      margin-top: 16px;
    }

    &__field {
      margin-top: 30px;
    }

    &__policy-wrapper {
      margin-top: 50px;
    }

    &__small-text {
      font-size: 14px;
    }
  }
}

@include respond('medium') {
  .modal-form {
    &__wrap {
      padding: 80px 47px;
    }

    &__policy-text {
      margin-left: 21px;
      max-width: 304px;
    }
  }
}

@include respond-down('small') {
  .modal-form {
    &__wrap {
      padding: 100px var(--grid-spacer);
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: 100vw;
        height: 1px;
        background: var(--lines-color);
        top: 50px;
        left: 0;
      }
    }

    &__small-text {
      font-size: 12px;
    }

    &__description {
      margin-top: 20px;
    }

    &__field {
      margin-top: 20px;

      &:first-child {
        margin-top: 40px;
      }
    }

    &__policy-wrapper {
      margin-top: 40px;
      flex-direction: column;
    }

    &__policy-text {
      margin-top: 20px;
      text-align: center;
    }
  }
}
