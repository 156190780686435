.about-price {
  overflow: scroll hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  &__description {
    text-align: center;
  }

  &__item {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 54px;
    background: var(--white-35);

    //&_green {
      background: var(--secondary-color);
    //}
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }

  &__item-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__description {
    margin-top: 20px;
  }

  &__list {
    display: flex;
    width: fit-content;
  }

  &__item {
    border: 1px solid var(--white-50);

    //&:last-child {
      border-color: var(--secondary-color);

      .about-price {
        &__icon {
          background: var(--secondary-color);
        }

        &__description {
          color: var(--secondary-color);
        }
      }
    //}
  }
}

@include respond-up('large') {
  .about-price {
    margin-top: 70px;

    &__list {
      padding-left: calc(50vw + var(--grid-spacer) - var(--max-row-width) / 2);
      padding-right: calc(50vw + var(--grid-spacer) - var(--max-row-width) / 2);
    }

    &__item {
      height: 260px;
      width: 260px;
    }
  }
}

@include respond('medium') {
  .about-price {
    margin-top: 50px;
  }
}

@include respond-down('medium') {
  .about-price {
    &__item {
      width: calc(20vw - 2 * var(--grid-spacer) / 5);
      height: calc(20vw - 2 * var(--grid-spacer) / 5);
      min-width: 234px;
      min-height: 234px;
    }

    &__list {
      overflow: scroll;
      //margin-right: calc(0px - var(--grid-spacer));
      //margin-left: calc(0px - var(--grid-spacer));

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .about-price {
    margin-top: 40px;
  }
}

