.success-form{

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__icon {
    border-radius: 50%;
    background: var(--secondary-color);
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

@include respond-up('large') {
  .success-form {
    &__wrap {
      padding: 140px 122px;
    }

    &__icon {
      width: 130px;
      height: 130px;
    }

    &__title {
      margin-top: 50px;
    }

    &__description {
      margin-top: 20px;
    }
  }
}

@include respond('medium') {
  .success-form {
    &__wrap {
      padding: 145px 83px;
    }

    &__description {
      margin-top: 16px;
    }
  }
}

@include respond-down('medium') {
  .success-form {
    &__icon {
      width: 110px;
      height: 110px;

      svg {
        width: 48px;
        height: 39px;
      }
    }

    &__title {
      margin-top: 40px;
    }
  }
}

@include respond-down('small') {
  .success-form {
    &__wrap {
      padding: 180px 70px;
    }

    &__description {
      margin-top: 12px;
      text-align: center;
    }
  }
}
