.model-slider {
  &__picture {
    display: flex;
    width: 100%;
    height: auto;
    max-height: 65vh;

    &_thumbs {
      max-height: 20vh;
    }
  }

  &_thumbs {
    .swiper-slide {
      opacity: 0.4;
    }

    .swiper-slide-thumb-active {
      opacity: 1;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__form-wrapper {
    display: flex;
    flex-direction: row;
    position: relative;
  }

  &__form {
    background: white;

    .main-form__policy-text {
      margin-left: 30px;
      margin-top: 0;
    }
  }
}

@include respond-up("large") {
  .model-slider {
    margin-top: clamp(25px, calc(40 * 100vw / 1920), 40px);

    &__form-text {
      padding: 44px 0 0 clamp(25px,4.6875vw,90px);
    }

    &__form-wrapper {
      padding: 0 70px 70px 60px;
    }

    &__form-heading-wrapper {
      padding-top: 44px;
      padding-right: clamp(25px, calc(90 * 100vw / 1920), 90px);
      border-right: 1px solid var(--lines-color);
      flex: 0 0 51%;
    }

    &__form {
      padding: 20px clamp(25px, calc(90 * 100vw / 1920), 90px) 40px;
      width: var(--grid-column5);

      .button {
        flex: 0 0 138px;
      }
    }

    &_mini {
      margin-top: clamp(15px, calc(20 * 100vw / 1920), 20px);
      margin-bottom: clamp(35px, calc(50 * 100vw / 1920), 50px);
    }

    &__heading {
      padding-bottom: clamp(15px, calc(30 * 100vw / 1920), 30px);
      padding-top: clamp(35px, calc(50 * 100vw / 1920), 50px);
    }

    &__wrapper {
      width: var(--grid-column10);
      padding: 0 20px;
    }
  }
}

@include respond-up("medium") {
  .model-slider {
    &__heading {
      border-bottom: 1px solid var(--lines-color);
    }
  }
}

@include respond("medium") {
  .model-slider {
    margin-top: 27px;

    &__slider-wrap {
      .slider-nav {
        &__wrapper {
          position: absolute;
          right: 0;
          top: calc(0px - (50px + 27px + 16px));
        }

        &__wide {
          .slider-nav {
            top: 0;
            left: 0;
            right: 0;
            transform: translateY(0);
            position: relative;
          }
        }
      }
    }

    &__form-text {
      padding: 44px clamp(25px,3.75vw,60px) 0 clamp(25px,3.75vw,60px);
    }

    &__form-wrapper {
      padding: 0 calc(70 * 100vw / 1600) calc(70 * 100vw / 1600) calc(60 * 100vw / 1600) ;
    }

    &__form-heading-wrapper {
      padding-top: 44px;
      padding-right: clamp(25px, calc(90 * 100vw / 1600), 90px);
      border-right: 1px solid var(--lines-color);
      flex: 0 0 50%;
    }

    &__form {
      padding: 15px clamp(25px, calc(60 * 100vw / 1600), 60px) 30px;
      width: var(--grid-column4);

      .main-form__policy-wrapper {
        flex-direction: row;
      }

      .button {
        flex: 0 0 118px;
      }

      .main-form__policy-text {
        text-align: left;
      }
    }

    &_mini {
      margin-top: 20px;
      margin-bottom: 40px;
    }

    &__heading {
      padding-bottom: 22px;
      padding-top: 40px;
    }

    &__wrapper {
      width: var(--grid-column8);
      padding: 0 20px;
    }
  }
}

@include respond-down("medium") {
  .model-slider {
    &__slider-wrap {
      position: relative;
    }
  }
}

@include respond-down("small") {
  .model-slider {
    margin-top: 20px;

    &__slider-wrap {
      padding-bottom: 90px;
    }

    &__form-text {
      margin-top: 30px;
      padding-top: 30px;
      border-top: 1px solid var(--lines-color);
    }

    &__form-wrapper {
      flex-direction: column;
      padding: 20px;
    }

    &_mini {
      display: none;
    }

    &__heading {
      padding: 50px var(--grid-spacer) 0 var(--grid-spacer);
    }

    &__wrapper {
      width: 100vw;
      height: auto;
      background: white;
    }
  }
}
