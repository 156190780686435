.partners {
  position: relative;

  &__wrapper {
    width: 100%;

    &.swiper {
      padding-right: 1px;
    }
  }

  &__list {
    border-left: 1px solid white;
  }

  &__item-hover-wrapper {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    overflow: hidden;
  }

  &__item-hover {
    width: 100%;
    height: 100%;
    background: var(--secondary-color);
    opacity: 0;
    transform: translateY(50%);
    transition: opacity .2s var(--default-transition-function), transform var(--default-timing) var(--default-transition-function);
  }

  &__item {
    border-right: 1px solid white;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    height: 100%;
    position: relative;
    display: flex;
    align-items: flex-end;

    &:hover {
      .partners {
        &__item-hover {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  &__picture {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    max-width: 150px;
    max-height: 80px;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@include respond-up("large") {
  .partners {
    &__item-hover-text {
      margin-top: 24px;
    }

    &__wrapper {
      margin-top: 70px;
    }

    &__item-hover {
      padding: 40px;
    }

    &__item {
      &.swiper-slide {
        padding: 40px;
        //width: clamp(433 / 370 * 100px, calc(var(--grid-column4) + var(--grid-gap) / 2), 433 / 370 * 55vh);
        height: clamp(100px, calc(370 / 433 * (var(--grid-column4) + var(--grid-gap) / 2)), 55vh);
      }
    }
  }
}

@include respond("large") {
  .partners {
  }
}

@include respond-down("large") {
  .partners {
  }
}

@include respond-up("medium") {
  .partners {
    &__nav {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

@include respond("medium") {
  .partners {
    &__item-hover-text {
      margin-top: 14px;
    }

    &__wrapper {
      margin-top: 44px;
    }

    &__item-hover {
      padding: 24px;
    }

    &__item {
      &.swiper-slide {
        padding: 24px;
        width: clamp(336 / 287 * 100px, calc(var(--grid-column4) - var(--grid-column1) / 2), 336 / 287 * 55vh);
        height: clamp(100px, calc(287 / 336 * (var(--grid-column4) - var(--grid-column1) / 2)), 55vh);
      }
    }
  }
}

@include respond-down("medium") {
  .partners {
    &__slider-wrapper {
      margin: 0 calc(0px - var(--grid-spacer));
      padding: 0 calc(var(--grid-spacer));
      overflow: hidden;
    }

    &__wrapper {
      &.swiper {
        overflow: visible;
      }
    }
  }
}

@include respond-down("small") {
  .partners {
    &__nav {
      margin-top: 20px;
    }

    &__item-hover-text {
      margin-top: 14px;
    }

    &__wrapper {
      margin-top: 40px;

      &.swiper {
        overflow: visible;
      }
    }

    &__item-hover {
      padding: 24px;
    }

    &__item {
      &.swiper-slide {
        padding: 24px;
        width: clamp(312 / 266 * 100px, calc(var(--grid-column4) - var(--grid-column1) / 2), 312 / 266 * 55vh);
        height: clamp(100px, calc(266 / 312 * (var(--grid-column4) - var(--grid-column1) / 2)), 55vh);
      }
    }
  }
}
