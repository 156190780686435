.project-slider {
  &__picture {
    display: flex;
    width: 100%;
    height: auto;
    max-height: 65vh;

    &_thumbs {
      max-height: 20vh;
    }
  }

  &_thumbs {
    .swiper-slide {
      opacity: 0.4;
    }

    .swiper-slide-thumb-active {
      opacity: 1;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__form-wrapper {
    display: flex;
    flex-direction: row;
    position: relative;
  }

  &__form {
    background: white;

    .main-form__policy-text {
      margin-left: 30px;
    }
  }
}

@include respond-up("large") {
  .project-slider {
    margin-top: clamp(25px, calc(40 * 100vw / 1920), 40px);

    &__form-wrapper {
      padding: 0 70px 70px 60px;
    }

    &__form-heading-wrapper {
      padding-top: 64px;
      padding-right: clamp(25px, calc(90 * 100vw / 1920), 90px);
      border-right: 1px solid var(--lines-color);
    }

    &__form {
      padding: 20px clamp(25px, calc(90 * 100vw / 1920), 90px) 40px;
      width: var(--grid-column5);

      .button {
        flex: 0 0 138px;
      }
    }

    &_mini {
      margin-top: clamp(15px, calc(20 * 100vw / 1920), 20px);
      margin-bottom: clamp(35px, calc(50 * 100vw / 1920), 50px);
    }

    &__heading {
      padding-bottom: clamp(15px, calc(30 * 100vw / 1920), 30px);
      padding-top: clamp(35px, calc(50 * 100vw / 1920), 50px);
    }

    &__wrapper {
      width: var(--grid-column10);
      padding: 0 20px;
    }
  }
}

@include respond-up("medium") {
  .project-slider {
    &__heading {
      border-bottom: 1px solid var(--lines-color);
    }
  }
}

@include respond("medium") {
  .project-slider {
    margin-top: 27px;

    &__form-wrapper {
      padding: 0 calc(70 * 100vw / 1600) calc(70 * 100vw / 1600) calc(60 * 100vw / 1600) ;
    }

    &__form-heading-wrapper {
      padding-top: 64px;
      padding-right: clamp(25px, calc(90 * 100vw / 1600), 90px);
      border-right: 1px solid var(--lines-color);
    }

    &__form {
      padding: 15px clamp(25px, calc(60 * 100vw / 1600), 60px) 30px;
      width: var(--grid-column4);

      .main-form__policy-wrapper {
        flex-direction: row;
      }

      .button {
        flex: 0 0 118px;
      }

      .main-form__policy-text {
        text-align: left;
      }
    }

    &_mini {
      margin-top: 20px;
      margin-bottom: 40px;
    }

    &__heading {
      padding-bottom: 22px;
      padding-top: 40px;
    }

    &__wrapper {
      width: var(--grid-column8);
      padding: 0 20px;
    }
  }
}

@include respond-down("medium") {
  .project-slider {
  }
}

@include respond-down("small") {
  .project-slider {
    margin-top: 20px;

    &__form-wrapper {
      flex-direction: column;
      padding: 20px;
    }

    &_mini {
      margin-top: 20px;
      margin-bottom: 80px;
    }

    &__heading {
      padding-top: 50px;
    }

    &__wrapper {
      width: 100vw;
      height: auto;
      background: white;
    }
  }
}
