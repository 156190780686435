@include respond-up('large') {
  .block {
    margin-top: 200px;
  }
}

@include respond('medium') {
  .block {
    margin-top: 130px;
  }
}

@include respond-down('small') {
  .block {
    margin-top: 100px;
  }
}