.hamburger {
  padding: 6px 15px;

  &__wrap {
    position: relative;
    display: flex;
    height: 12px;
    width: 48px;
  }

  &__line {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--white);
    transform-origin: center;
    transition: var(--default-transition);

    &_top {
      top: 0;
      transition-property: background-color, top, transform;
    }

    &_bottom {
      bottom: 0;
      transition-property: background-color, bottom, transform;
    }
  }

  &._open {
    .hamburger__line {
      background-color: #fff;

      &_top {
        top: 5px;
        transform: rotate(45deg);
        width: 26px;
      }

      &_bottom {
        bottom: 5px;
        transform: rotate(-45deg);
        width: 26px;
      }
    }
  }
}

@include respond-up('large') {
  .hamburger {
    &._open {
      &:hover {
        .hamburger__line {
          background: var(--secondary-color);
        }
      }
    }

    &:hover {
      .hamburger {
        &__line {
          background: var(--secondary-color);
        }
      }
    }
  }
}

@include respond-down('small') {
  .hamburger {

    &__wrap {
      width: 30px;
    }

    &._open {
      .hamburger__line {

        &_top {
          width: 21px;
        }

        &_bottom {
          width: 21px;
        }
      }
    }
  }
}