.drop-menu {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  background-color: var(--primary-color);
  transition: opacity var(--default-timing) var(--default-transition-function);
  z-index: 4500;

  &__link {
    display: block;
  }

  &._open {
    opacity: 1;
    pointer-events: auto;
  }

  &._close {
    transition-delay: .2s;
  }

  &__wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__item {
    color: #fff;
    font-weight: 300;
    line-height: 130%;
  }
}

@include respond-up('large') {
  .drop-menu {
    width: 570px;

    &__wrap {
      padding: 160px 0 50px 72px;
    }

    &__link {
      .drop-menu__text {
        position: relative;

        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          bottom: -10px;
          width: 100%;
          height: 2px;
          background-color: var(--secondary-color);
          transform: scaleX(0);
          transform-origin: right;
          transition: transform .4s var(--default-bezier);
        }
      }

      &:hover {
        .drop-menu__text {
          &:before {
            transform-origin: left;
            transform: scaleX(1);
            transition: transform .4s var(--default-bezier);
          }
        }
      }
    }
  }
}


@include respond-up('medium') {
  .drop-menu {
    &__wrap {
      border-right: 1px solid var(--white-50);
    }

    &__item {
      font-size: 30px;
    }

    &__list {
      margin-top: -23px;
    }

    &__link {
      padding: 23px 0;
    }
  }
}

@include respond('medium') {
  .drop-menu {
    width: 552px;

    &__wrap {
      padding: 160px 0 50px var(--wide-grid-spacer);
    }
  }
}

@include respond-down('small') {
  .drop-menu {
    width: 100vw;

    &__item {
      font-size: 24px;
    }

    &__list {
      margin-top: -20px;
    }

    &__link {
      padding: 20px 0;
    }

    &__wrap {
      padding: 100px 0 50px var(--wide-grid-spacer);
    }
  }
}