.work-format {
  &__wrapper {
    .grid-block {
      grid-row-gap: 0;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--white-50);
    overflow: hidden;
    transition: var(--default-transition);

    &._opened {
      height: auto;
      border-width: 2px;
      border-color: var(--secondary-color);

      .work-format {
        &__item-arrow {
          svg {
            transform-origin: center;
            transform: rotate(180deg);

            path {
              stroke: var(--secondary-color);
            }
          }
        }
      }
    }
  }

  &__item-top {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
  }

  &__item-bottom {
    height: 0;
    transition: var(--default-transition);
    overflow: hidden;
  }

  &__item-arrow {
    svg {
      transition: var(--default-transition);

      path {
        transition: var(--default-transition);
      }
    }
  }
}

@include respond-up('large') {
  .work-format {
    &__title {
      grid-column: 1/5;
    }

    &__list {
      display: flex;
      flex-direction: column;
      grid-column: 6/13;
    }

    &__item {
      max-width: 750px;

      &._opened {
        .work-format {
          &__item-bottom {
            margin-top: 4px;
            margin-bottom: 24px;
          }
        }
      }

      &:first-child {
        margin-top: -24px;
      }
    }

    &__item-title {
      margin-left: 35px;
    }

    &__item-bottom {
      padding: 0 75px 0 60px;
    }

    &__item-top {
      padding-bottom: 24px;
      padding-top: 24px;

      &:hover {
        .work-format {
          &__item-arrow {
            svg {
              transform-origin: center;
              transform: rotate(180deg);

              path {
                stroke: var(--secondary-color);
              }
            }
          }
        }
      }
    }

    &__item-arrow {
      top: 9px;
    }
  }
}

@include respond('medium') {
  .work-format {
    &__title,
    &__list {
      grid-column: 1/9;
    }

    &__title {
      padding-bottom: 50px;
      border-bottom: 1px solid var(--white-50);
    }

    &__list {
      margin-top: 22px;
    }

    &__item {
      padding-bottom: 22px;

      &._opened {
        .work-format {
          &__item-bottom {
            margin-top: 18px;
          }
        }
      }

      &:not(:first-child) {
        margin-top: 22px;
      }
    }

    &__item-title {
      margin-left: 35px;
    }

    &__item-arrow {
      top: 4px;
    }

    &__item-bottom {
      padding: 0 20px 0 58px;
    }
  }
}

@include respond-down('small') {
  .work-format {
    &__title,
    &__list {
      grid-column: 1/5;
    }

    &__title {
      padding-bottom: 40px;
      border-bottom: 1px solid var(--white-50);
    }

    &__list {
      margin-top: 15px;
    }

    &__item {
      padding-bottom: 16px;

      &._opened {
        .work-format {
          &__item-bottom {
            margin-top: 16px;
          }
        }
      }

      &:not(:first-child) {
        margin-top: 16px;
      }
    }

    &__item-title {
      margin-left: 20px;
    }

    &__item-arrow {
      top: 4px;

      svg {
        path {
          stroke-opacity: 1;
        }
      }
    }
  }
}