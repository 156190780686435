.footer {
  &__list {
    display: flex;
  }

  &__top-wrap {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: calc(0px - var(--wide-grid-spacer));
      height: 1px;
      width: 100vw;
      background: var(--white-50);
    }
  }

  &__contacts {
    display: flex;
    align-items: center;
  }
}

@include respond-up('large') {
  .footer {
    &__item {
      &:not(:first-child) {
        margin-left: 35px;
      }
    }

    &__top-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 56px 0 46px;
    }

    &__social-wrap {
      margin-left: 36px;
    }

    &__bottom-wrap {
      padding: 60px 0 60px;
    }

    &__phone,
    &__link {
      position: relative;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: -10px;
        width: 100%;
        height: 2px;
        background-color: var(--secondary-color);
        transform: scaleX(0);
        transform-origin: right;
        transition: transform .4s var(--default-bezier);
      }

      &:hover {
        &:before {
          transform-origin: left;
          transform: scaleX(1);
          transition: transform .4s var(--default-bezier);
        }
      }
    }
  }
}

@include respond-up('medium') {
  .footer {
    &__bottom-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

@include respond('medium') {
  .footer {
    &__item {
      padding-top: 20px;
      &:not(:last-child) {
        margin-right: 50px;
      }
    }

    &__top-wrap {
      padding: 30px 0 30px;
    }

    &__bottom-wrap {
      padding: 30px 0 35px;
    }

    &__contacts {
      margin-top: 50px;
      justify-content: space-between;
    }

    &__phone-wrap {
      order: 2;
    }

    &__list {
      flex-wrap: wrap;
    }
  }
}

@include respond-down('small') {
  .footer {
    &__top-wrap {
      padding: 110px 0 30px;
    }

    &__bottom-wrap {
      padding: 30px 0 30px;
    }

    &__list {
      flex-direction: column;
    }

    &__item {
      &:not(:first-child) {
        margin-top: 30px;
      }
    }

    &__contacts {
      margin-top: 50px;
      justify-content: space-between;
    }

    &__rclass {
      margin-top: 20px;
    }
  }
}