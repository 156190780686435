/*
spacer - Задаёт фиксированные отступы по краям контейнера.

Использование в шаблоне:

<div class="container container_spacer">
  ...
</div>

По умолчанию в качестве единиц измерения для отступа используются - 'px'.
При необходимости их можно изменить переменной $spacer-unit в файлике ../_common/_container.scss
*/

$grid: (
  small: (
    from: 0,
    to: 767
  ),
  medium: (
    from: 768,
    to: 1599
  ),
  large: (
    from: 1600
  )
);

:root {
  --body-font-size: 16px;
  --body-line-height: 1.3;

  --font: "Helvetica Neue", sans-serif;
  --h-font: "Helvetica Neue", sans-serif;

  --t: transparent;

  --primary-color: #163446; // фон
  --secondary-color: #52BB65; // зелёный
  --dark-blue: #011420; // темно-синий
  --dark-blue-70: rgba(1, 20, 32, 0.7); // темно-синий 70%
  --lines-color: rgba(1, 20, 32, 0.3); // линии

  --white: white;
  --white-70: rgba(255, 255, 255, 0.7);
  --white-50: rgba(255, 255, 255, 0.5);
  --white-35: rgba(255, 255, 255, 0.35);
  --white-20: rgba(255, 255, 255, 0.2);

  --primary-color-opacity: rgba(82, 187, 101, 0.85);
  --alert-color: var(--primary-color);

  --default-timing: 0.4s;
  --default-transition-function: ease-in-out;
  --default-transition: all var(--default-timing) var(--default-transition-function);
  --default-bezier: cubic-bezier(.25, .1, .25, 1);
}
