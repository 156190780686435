/*@font-face {*/
/*    font-family: 'helvetica neue';*/
/*    src: url('helveticaneueboldcondensed.eot');*/
/*    src: local('helvetica neue condensed bold'), local('helveticaneueboldcondensed'),*/
/*        url('helveticaneueboldcondensed.eot?#iefix') format('embedded-opentype'),*/
/*        url('helveticaneueboldcondensed.woff2') format('woff2'),*/
/*        url('helveticaneueboldcondensed.woff') format('woff'),*/
/*        url('helveticaneueboldcondensed.ttf') format('truetype');*/
/*    font-weight: bold;*/
/*    font-style: normal;*/
/*}*/

/*@font-face {*/
/*    font-family: 'helvetica neue';*/
/*    src: url('helveticaneueblackcondensed.eot');*/
/*    src: local('helvetica neue condensed black'), local('helveticaneueblackcondensed'),*/
/*        url('helveticaneueblackcondensed.eot?#iefix') format('embedded-opentype'),*/
/*        url('helveticaneueblackcondensed.woff2') format('woff2'),*/
/*        url('helveticaneueblackcondensed.woff') format('woff'),*/
/*        url('helveticaneueblackcondensed.ttf') format('truetype');*/
/*    font-weight: 900;*/
/*    font-style: normal;*/
/*}*/

@font-face {
    font-family: 'helveticaneuecyr';
    src: url('helveticaneuecyr-bold.eot');
    src: local('helveticaneuecyr-bold'),
        url('helveticaneuecyr-bold.eot?#iefix') format('embedded-opentype'),
        url('helveticaneuecyr-bold.woff2') format('woff2'),
        url('helveticaneuecyr-bold.woff') format('woff'),
        url('helveticaneuecyr-bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'helveticaneuecyr';
    src: url('helveticaneuecyr-light.eot');
    src: local('helveticaneuecyr-light'),
        url('helveticaneuecyr-light.eot?#iefix') format('embedded-opentype'),
        url('helveticaneuecyr-light.woff2') format('woff2'),
        url('helveticaneuecyr-light.woff') format('woff'),
        url('helveticaneuecyr-light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'helveticaneuecyr';
    src: url('helveticaneuecyr-medium.eot');
    src: local('helveticaneuecyr-medium'),
        url('helveticaneuecyr-medium.eot?#iefix') format('embedded-opentype'),
        url('helveticaneuecyr-medium.woff2') format('woff2'),
        url('helveticaneuecyr-medium.woff') format('woff'),
        url('helveticaneuecyr-medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'helveticaneuecyr';
    src: url('helveticaneuecyr-mediumitalic.eot');
    src: local('helveticaneuecyr-mediumitalic'),
        url('helveticaneuecyr-mediumitalic.eot?#iefix') format('embedded-opentype'),
        url('helveticaneuecyr-mediumitalic.woff2') format('woff2'),
        url('helveticaneuecyr-mediumitalic.woff') format('woff'),
        url('helveticaneuecyr-mediumitalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'helveticaneuecyr';
    src: url('helveticaneuecyr-lightitalic.eot');
    src: local('helveticaneuecyr-lightitalic'),
        url('helveticaneuecyr-lightitalic.eot?#iefix') format('embedded-opentype'),
        url('helveticaneuecyr-lightitalic.woff2') format('woff2'),
        url('helveticaneuecyr-lightitalic.woff') format('woff'),
        url('helveticaneuecyr-lightitalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'helveticaneuecyr';
    src: url('helveticaneuecyr-heavyitalic.eot');
    src: local('helveticaneuecyr-heavyitalic'),
        url('helveticaneuecyr-heavyitalic.eot?#iefix') format('embedded-opentype'),
        url('helveticaneuecyr-heavyitalic.woff2') format('woff2'),
        url('helveticaneuecyr-heavyitalic.woff') format('woff'),
        url('helveticaneuecyr-heavyitalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'helveticaneuecyr';
    src: url('helveticaneuecyr-black.eot');
    src: local('helveticaneuecyr-black'),
        url('helveticaneuecyr-black.eot?#iefix') format('embedded-opentype'),
        url('helveticaneuecyr-black.woff2') format('woff2'),
        url('helveticaneuecyr-black.woff') format('woff'),
        url('helveticaneuecyr-black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'helveticaneuecyr';
    src: url('helveticaneuecyr-heavy.eot');
    src: local('helveticaneuecyr-heavy'),
        url('helveticaneuecyr-heavy.eot?#iefix') format('embedded-opentype'),
        url('helveticaneuecyr-heavy.woff2') format('woff2'),
        url('helveticaneuecyr-heavy.woff') format('woff'),
        url('helveticaneuecyr-heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'helveticaneuecyr';
    src: url('helveticaneuecyr-italic.eot');
    src: local('helveticaneuecyr-italic'),
        url('helveticaneuecyr-italic.eot?#iefix') format('embedded-opentype'),
        url('helveticaneuecyr-italic.woff2') format('woff2'),
        url('helveticaneuecyr-italic.woff') format('woff'),
        url('helveticaneuecyr-italic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'helveticaneuecyr';
    src: url('helveticaneuecyr-roman.eot');
    src: local('helveticaneuecyr-roman'),
        url('helveticaneuecyr-roman.eot?#iefix') format('embedded-opentype'),
        url('helveticaneuecyr-roman.woff2') format('woff2'),
        url('helveticaneuecyr-roman.woff') format('woff'),
        url('helveticaneuecyr-roman.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'helveticaneuecyr';
    src: url('helveticaneuecyr-blackitalic.eot');
    src: local('helveticaneuecyr-blackitalic'),
        url('helveticaneuecyr-blackitalic.eot?#iefix') format('embedded-opentype'),
        url('helveticaneuecyr-blackitalic.woff2') format('woff2'),
        url('helveticaneuecyr-blackitalic.woff') format('woff'),
        url('helveticaneuecyr-blackitalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'helveticaneuecyr';
    src: url('helveticaneuecyr-ultralightitalic.eot');
    src: local('helveticaneuecyr-ultralightitalic'),
        url('helveticaneuecyr-ultralightitalic.eot?#iefix') format('embedded-opentype'),
        url('helveticaneuecyr-ultralightitalic.woff2') format('woff2'),
        url('helveticaneuecyr-ultralightitalic.woff') format('woff'),
        url('helveticaneuecyr-ultralightitalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'helveticaneuecyr';
    src: url('helveticaneuecyr-bolditalic.eot');
    src: local('helveticaneuecyr-bolditalic'),
        url('helveticaneuecyr-bolditalic.eot?#iefix') format('embedded-opentype'),
        url('helveticaneuecyr-bolditalic.woff2') format('woff2'),
        url('helveticaneuecyr-bolditalic.woff') format('woff'),
        url('helveticaneuecyr-bolditalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'helveticaneuecyr';
    /*src: url('helveticaneuecyr-thinitalic.eot');*/
    src: local('helveticaneuecyr-thinitalic'),
        /*url('helveticaneuecyr-thinitalic.eot?#iefix') format('embedded-opentype'),*/
        url('helveticaneuecyr-thinitalic.woff2') format('woff2'),
        url('helveticaneuecyr-thinitalic.woff') format('woff'),
        url('helveticaneuecyr-thinitalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'helveticaneuecyr';
    src: url('helveticaneuecyr-ultralight.eot');
    src: local('helveticaneuecyr-ultralight'),
        url('helveticaneuecyr-ultralight.eot?#iefix') format('embedded-opentype'),
        url('helveticaneuecyr-ultralight.woff2') format('woff2'),
        url('helveticaneuecyr-ultralight.woff') format('woff'),
        url('helveticaneuecyr-ultralight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'helveticaneuecyr';
    src: url('helveticaneuecyr-thin.eot');
    src: local('helveticaneuecyr-thin'),
        url('helveticaneuecyr-thin.eot?#iefix') format('embedded-opentype'),
        url('helveticaneuecyr-thin.woff2') format('woff2'),
        url('helveticaneuecyr-thin.woff') format('woff'),
        url('helveticaneuecyr-thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

