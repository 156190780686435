.header {
  position: fixed;
  width: 100%;
  z-index: 4700;

  &__container {
    border-bottom: 1px solid var(--white-50);
    background: var(--primary-color);
    position: relative;
    z-index: 4600;
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);

    svg {
      display: flex;
    }
  }
}

@include respond-up('large') {
  .header {
    &__phone-wrap-mobile {
      display: none;
    }

    &__links-wrap {
      gap: 35px;
    }

    &__phone-wrap {
      position: relative;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: -10px;
        width: 100%;
        height: 2px;
        background-color: var(--secondary-color);
        transform: scaleX(0);
        transform-origin: right;
        transition: transform .4s var(--default-bezier);
      }

      &:hover {
        &:before {
          transform-origin: left;
          transform: scaleX(1);
          transition: transform .4s var(--default-bezier);
        }
      }
    }
  }
}

@include respond-up('medium') {
  .header {
    &__links-wrap {
      display: flex;
      align-items: center;
    }

    &__wrap {
      height: 80px;
    }
  }
}

@include respond('medium') {
  .header {
    &__links-wrap {
      gap: 20px;
    }

    &__logo {
      svg {
        width: 92px;
        height: auto;
      }
    }
  }
}


@include respond-down('medium') {
  .header {
    &__phone-wrap {
      &_desktop{
        display: none;
      }
    }

    &__phone-mobile {
      width: 30px;
      height: 30px;
      border: 1px solid var(--white-20);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: var(--default-transition);
    }
  }
}

@include respond-down('small') {
  .header {
    &__wrap {
      height: 50px;
    }

    &__phone-wrap_tablet{
     display: none;
    }

    &__logo {
      padding-top: 3px;

      svg {
        width: 66px;
        height: auto;
      }
    }
  }
}