.main-form {
  background: var(--secondary-color);

  .modal-form__submit {
    flex: 0 0 auto;
  }

  &__form {
    background: white;
  }

  &__text-h2 {
    border-bottom: 1px solid var(--white-50);
  }

  &__policy-wrapper {
    display: flex;
  }
}

@include respond-up("large") {
  .main-form {
    padding-top: 105px;
    padding-bottom: 100px;
    margin-top: 315px;

    &__policy-wrapper {
      margin-top: 50px;
      align-items: center;
    }

    &__policy-text {
      margin-left: 70px;
    }

    &__text-h2 {
      padding-bottom: 30px;
    }

    &__text-main {
      padding-top: 30px;
      width: var(--grid-column4);
    }

    &__form {
      grid-column: 6/13;
      margin-top: -220px;
      margin-left: var(--grid-column1);
      padding: 65px var(--grid-column1) calc(var(--grid-column1) + 5px);
    }

    &__text {
      grid-column: 1/6;
    }
  }
}

@include respond-up("medium") {
  .main-form {
  }
}

@include respond("medium") {
  .main-form {
    padding-top: 60px;
    padding-bottom: 45px;
    margin-top: 225px;

    &__text-h2 {
      padding-bottom: 22px;
    }

    &__text-main {
      padding-top: 22px;
    }

    &__text {
      grid-column: 1/5;
    }

    &__form {
      grid-column: 5/9;
      padding: 10px 25px 50px;
      margin-top: -155px;
    }
  }
}

@include respond-down("medium") {
  .main-form {
    padding-top: 40px;

    &__text-h2 {
      padding-bottom: 20px;
    }

    &__text-main {
      padding-top: 20px;
    }

    &__policy-wrapper {
      margin-top: 40px;
      flex-direction: column;
      align-items: center;
    }

    &__policy-text {
      margin-top: 14px;
      text-align: center;
    }
  }
}

@include respond-down("small") {
  .main-form {
    margin-top: 100px;

    &__text,
    &__form {
      grid-column: 1/5;
    }

    &__form {
      padding: 40px 16px;
      margin-top: 40px;
      margin-bottom: -50px;
    }
  }
}
