.stage-slider {
  &__item {
    .grid-block {
      grid-row-gap: 0;
    }
  }

  &__description {
    background: var(--primary-color);
  }

  &__wrapper {
    position: relative;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }

  &__stage-heading {
    display: flex;
    align-items: flex-start;
  }

  &__name {
    padding-left: 20px;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: auto;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up("large") {
  .stage-slider {
    &__nav {
      position: absolute;
      bottom: 150px;
      left: var(--grid-column8);
      z-index: 150;
    }

    &__text {
      margin-top: 35px;
    }

    &__wrapper {
      margin-top: 70px;
      padding-bottom: 70px;
    }

    &__picture {
      grid-column: span 7;
    }

    &__description {
      grid-column: span 5;
      padding-top: var(--grid-column1);
      padding-left: var(--grid-column1);
      position: relative;
      z-index: 100;
    }

    &__position {
      //padding-top: 9px;
    }
  }
}

@include respond-up("medium") {
  .stage-slider {
  }
}

@include respond("medium") {
  .stage-slider {
    &__position {
      //padding-top: 5px;
    }

    &__nav {
      position: absolute;
      bottom: 90px;
      left: var(--grid-column4);
      z-index: 150;
    }

    &__description {
      padding-top: 30px;
    }

    &__picture {
      width: calc(var(--grid-column4) - var(--grid-column1) / 2);
    }

    &__wrapper {
      margin-top: 50px;
      padding-bottom: 50px;
    }

    &__text {
      margin-top: 22px;
    }
  }
}

@include respond-down("medium") {
  .stage-slider {
    &__description,
    &__picture {
      grid-column: span 4;
    }
  }
}

@include respond-down("small") {
  .stage-slider {
    &__position {
      //padding-top: 2px;
    }

    &__nav {
      margin-top: 50px;
    }

    &__description {
      margin-top: 30px;
    }

    &__text {
      margin-top: 20px;
    }

    &__wrapper {
      margin-top: 40px;
      padding-bottom: 40px;
    }
  }
}
