h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: var(--h-font);
  font-size: var(--h-size);
  line-height: 120%;
  font-weight: 400;
}

@include respond-up('large') {
  h1, .h1 {
    --h-size: 60px;
  }

  h2, .h2 {
    --h-size: 45px;
  }

  h3, .h3 {
    --h-size: 30px;
  }

  h4, .h4 {
    --h-size: 20px;
  }

  h5, .h5 {
    --h-size: 20px;
  }

  h6, .h6 {
    --h-size: 20px;
  }
}

@include respond('medium') {
  h1, .h1 {
    --h-size: 40px;
  }

  h2, .h2 {
    --h-size: 32px;
  }

  h3, .h3 {
    --h-size: 24px;
  }

  h4, .h4 {
    --h-size: 24px;
  }

  h5, .h5 {
    --h-size: 22px;
  }

  h6, .h6 {
    --h-size: 20px;
  }
}

@include respond-down('small') {
  h1, .h1 {
    --h-size: 34px;
  }

  h2, .h2 {
    --h-size: 22px;
  }

  h3, .h3 {
    --h-size: 20px;
  }

  h4, .h4 {
    --h-size: 20px;
  }

  h5, .h5 {
    --h-size: 20px;
  }

  h6, .h6 {
    --h-size: 20px;
  }
}