.layout {
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  flex-direction: column;
  background: var(--primary-color);
  color: white;

  &__main {
    padding-top: 100px;
  }

  p,
  li,
  pre,
  div,
  a,
  span,
  h1, h2, h3, h4, h5, h6 {
    &::selection {
      background-color: white;
      color: black;
      transition: background-color .4s var(--default-transition-function), color .4s var(--default-transition-function);
    }
  }

  .swiper-button-disabled {
    opacity: .4;
  }
}