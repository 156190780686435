.button {
  padding: 0;
  margin: 0;
  text-align: center;
  transition: var(--default-transition);
  display: inline-block;
  //background: var(--primary-color);
  cursor: pointer;
  text-decoration: none;
  border-radius: 100px;
  border: 1px solid var(--secondary-color);
  text-transform: uppercase;

  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;

  &__primary {
    background: var(--secondary-color);
    color: white;
  }

  &__secondary {
    background: white;
    color: var(--dark-blue);
  }

  &_expand, &_wide {
    width: 100%;
  }
}

a.button {
  display: flex;
  align-items: center;
  justify-content: center;
}

@include respond-up('large') {
  .button {
    width: 138px;
    height: 138px;

    &__primary {
      &:hover {
        background: var(--primary-color);
      }
    }

    &__secondary {
      &:hover {
        color: white;
        background: var(--secondary-color);
      }
    }
  }
}

@include respond-down('medium') {
  .button {
    width: 118px;
    height: 118px;
  }
}