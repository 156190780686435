.about-us {
  display: flex;

  &__text-wrap {
    background: #F3F5F7;
  }

  &__title {
    border-bottom: 1px solid var(--lines-color);
  }

  &__picture {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('large') {
  .about-us {
    &__text-wrap {
      width: var(--grid-column7);
      padding: 100px var(--grid-column);
    }

    &__image-wrap {
      width: calc(var(--grid-column5) + var(--grid-gap));
      position: relative;
    }

    &__title {
      padding-bottom: 30px;
    }

    &__description {
      margin-top: 30px;
    }
  }
}

@include respond-up('medium') {
  .about-us {

  }
}

@include respond('medium') {
  .about-us {
    &__text-wrap {
      width: calc(var(--grid-column4) + var(--grid-gap) + var(--grid-column) / 2);
      padding: 60px calc(var(--grid-column) / 2);
    }

    &__image-wrap {
      width: calc(var(--grid-column3) + var(--grid-gap) + var(--grid-column) / 2);
      position: relative;
    }

    &__title {
      padding-bottom: 22px;
    }

    &__description {
      margin-top: 22px;
    }
  }
}


@include respond-down('small') {
  .about-us {
    flex-direction: column;

    &__text-wrap {
      width: var(--grid-column4);
      padding: 40px var(--grid-spacer);
    }

    &__image-wrap {
      width: var(--grid-column4);
      position: relative;
      padding-bottom: 101%;
    }

    &__title {
      padding-bottom: 20px;
    }

    &__description {
      margin-top: 20px;
    }
  }
}