.slider-nav {
  border: 1px solid var(--white-50);
  transition: border-color .4s ease-in-out;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  &.swiper-button-disabled {
    opacity: .4;
  }

  &_left {
    margin-right: 25px;

    svg {
      transform: rotate(180deg);
    }
  }

  svg {
    display: flex;
    width: auto;
  }

  &__wrapper {
    display: flex;
  }
}

@include respond-up("large") {
  .slider-nav {
    width: 60px;
    height: 60px;

    svg {
      height: 20px;
    }

    &:hover {
      border-color: var(--white);
    }

    &__wide {
      .slider-nav {
        z-index: 4500;
        position: absolute;
        top: 30%;
        transform: translateY(-50%);

        &_left {
          left: -90px;
        }

        &_right {
          right: -90px;
        }
      }
    }
  }
}

@include respond-up("medium") {
  .slider-nav {
  }
}

@include respond("medium") {
  .slider-nav {
    width: 50px;
    height: 50px;
    &__wide {
      .slider-nav {
        z-index: 4500;
        position: absolute;
        top: 29%;
        transform: translateY(-50%);
      }
    }

    &__wide {
      //  position: absolute;
      //  top: 34px;
      //  right: 21px;
      //
      .slider-nav {
        border-color: var(--lines-color);
        background: var(--white-70);

        &_left {
          left: 45px;
        }

        &_right {
          right: 45px;
        }

        //
        //    &_left {
        //      margin-right: 9px;
        //    }
        //
        svg {
          path {
            stroke: var(--dark-blue);
          }
        }
      }
    }

    svg {
      height: 16px;
    }
  }
}

@include respond-down("medium") {
  .slider-nav {
  }
}

@include respond-down("small") {
  .slider-nav {
    width: 40px;
    height: 40px;

    &__wide {
      position: absolute;
      bottom: 22px;
      right: 19px;

      .slider-nav {
        border-color: var(--lines-color);

        &_left {
          margin-right: 9px;
        }

        svg {
          path {
            stroke: var(--dark-blue);
          }
        }
      }
    }

    svg {
      height: 13px;
    }
  }
}
