.social-icons {
  display: flex;
  &__link {
    width: 50px;
    height: 50px;
    border: 1px solid var(--white-70);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@include respond-up('large') {
  .social-icons {
    &__item {
      &:not(:first-child) {
        margin-left: 19px;
      }
    }

    &__link {
      transition: var(--default-transition);

      &:hover {
        border-color: var(--secondary-color);
      }
    }
  }
}

@include respond-down('medium') {
  .social-icons {
    &__item {
      &:not(:first-child) {
        margin-left: 14px;
      }
    }
  }
}