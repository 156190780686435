.error-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;

  &__description {
    margin-top: 20px;
  }

  &__button {
    margin-top: 40px;
  }
}