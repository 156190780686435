.projects-slider {
  &__item-wrap {
    display: flex;
    flex-direction: column;
  }

  &.swiper {
    padding-right: calc(var(--grid-spacer-and-indent) * 2);
  }

  &__picture {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__list {
    padding-left: var(--grid-spacer-and-indent);
    padding-right: var(--grid-spacer-and-indent);
  }

  &__wrapper {
    position: relative;
    margin-right: calc(0px - var(--grid-spacer-and-indent));
    margin-left: calc(0px - var(--grid-spacer-and-indent));
  }

  &__nav-wrap {
    position: absolute;
    right: var(--grid-spacer-and-indent);
  }
}

@include respond-up('large') {
  .projects-slider {
    &__wrapper {
      margin-top: 70px;
    }

    &__item {
      &.swiper-slide {
        width: clamp(424 / 279 * 100px, var(--grid-column8), 424 / 279 * 55vh);
      }

      .projects-slider__picture {
        height: clamp(100px, calc(279 / 424 * var(--grid-column8)), 55vh);
        width: clamp(424 / 279 * 100px, var(--grid-column8), 424 / 279 * 55vh);
      }

      &:not(:last-child) {
        margin-right: calc(var(--grid-column) + var(--grid-gap));
      }
    }

    &__nav-wrap {
      top: -155px;
    }
  }
}

@include respond-up('medium') {
  .projects-slider {
    &__item-text {
      margin-top: 20px;
    }

    &__nav-wrap {
      right: var(--grid-spacer-and-indent);
    }
  }
}

@include respond('medium') {
  .projects-slider {
    &__wrapper {
      margin-top: 50px;
    }

    &__nav-wrap {
      top: -112px;
    }

    &__item {
      &.swiper-slide {
        width: clamp(424 / 279 * 100px, var(--grid-column5), 424 / 279 * 55vh);
      }

      .projects-slider__picture {
        height: clamp(100px, calc(279 / 424 * var(--grid-column5)), 55vh);
        width: clamp(424 / 279 * 100px, var(--grid-column5), 424 / 279 * 55vh);
      }

      &:not(:first-child) {
        margin-left: calc(var(--grid-gap) * 3);
      }
    }
  }
}

@include respond-down('small') {
  .projects-slider {
    &__item-text {
      margin-top: 15px;
    }

    &__nav-wrap {
      left: var(--grid-spacer-and-indent);
      bottom: -60px;
    }

    &__nav-mobile-wrap {
      margin-top: 20px;
    }

    &__wrapper {
      margin-top: 40px;
    }

    &__item {
      &.swiper-slide {
        width: clamp(424 / 279 * 100px, var(--grid-column3), 424 / 279 * 55vh);
      }

      .projects-slider__picture {
        height: clamp(100px, calc(279 / 424 * var(--grid-column3)), 55vh);
        width: clamp(424 / 279 * 100px, var(--grid-column3), 424 / 279 * 55vh);
      }

      &:not(:first-child) {
        margin-left: var(--grid-gap);
      }
    }
  }
}