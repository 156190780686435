.main-page {
  &__sale {
    border: 1px solid var(--secondary-color);
    border-radius: 1000px;
    display: flex;
    justify-content: center;
  }

  &__picture {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__description {
    pre {
      white-space: normal;
    }
  }
}

@include respond-up('large') {
  .main-page {
    padding-top: 70px;

    &__sale {
      grid-column: 1;
      width: 76px;
      height: 35px;
      font-size: 16px;
      align-items: center;
    }

    &__text-wrap {
      grid-column: 1/10;
    }

    &__description {
      margin-top: 22px;
      width: calc(var(--grid-column6) + var(--grid-gap));

      pre {
        width: calc(var(--grid-column6) + var(--grid-gap));
      }
    }

    &__button-wrap {
      grid-column: 11/13;
      display: flex;
      justify-content: flex-end;
    }

    &__image-wrap {
      margin-top: clamp(66px, calc(100vw * 106 / 1920), 106px);
    }
  }
}

@include respond-up('medium') {
  .main-page {
    &__image-wrap {
      overflow: hidden;
      max-height: 60vh;
      display: flex;
      justify-content: center;
      align-items: center
    }
  }
}

@include respond('medium') {
  .main-page {
    padding-top: clamp(35px, calc(100vw * 70 / 1600), 70px);

    &__top-wrap {
      padding-bottom: clamp(65px, calc(125 * 100vw / 1600), 125px);
      position: relative;
    }

    &__sale {
      grid-column: 1;
    }

    &__text-wrap {
      grid-column: 1/9;
    }

    &__button-wrap {
      position: absolute;
      right: var(--grid-spacer);
      bottom: -59px;
    }
  }
}

@include respond-down('medium') {
  .main-page {
    &__sale {
      width: 66px;
      height: 30px;
      padding-top: 7px;
    }

    &__description {
      margin-top: 20px;
    }

    &__image-container {
      padding: 0;
    }
  }
}

@include respond-down('small') {
  .main-page {
    &__top-wrap {
      padding-bottom: 125px;
      position: relative;
    }

    &__sale {
      grid-column: 1;
    }

    &__text-wrap {
      grid-column: 1/5;
    }

    &__button-wrap {
      position: absolute;
      right: var(--grid-spacer);
      bottom: -59px;
    }
  }
}
