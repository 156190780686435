.contacts {
  &__map-block {
    margin-left: calc(0px - var(--grid-spacer-and-indent));
    margin-right: calc(0px - var(--grid-spacer-and-indent));
  }
}

@include respond-up('large') {
  .contacts {

    &__wrap {
      grid-row-gap: 50px;
    }

    &__phone-wrap {
      grid-column: 1/3;
      margin-top: 70px;
    }

    &__email-wrap {
      grid-column: 1/3;
    }

    &__address-wrap {
      grid-column: 5/10;
      grid-row: 1;
      margin-top: 70px;
    }

    &__schedule-wrap {
      grid-column: 5/10;
    }

    &__phone,
    &__email {
      display: block;
      padding: 20px 0;
    }

    &__address,
    &__schedule {
      margin-top: 20px;
    }

    &__map-block {
      margin-top: 100px;
    }

    &__map {
      width: calc(var(--grid-column12) + var(--grid-spacer-and-indent) * 2);
      height: 510px;
    }
  }
}

@include respond-up('medium') {
  .contacts {

  }
}

@include respond('medium') {
  .contacts {
    &__wrap {
      grid-row-gap: 36px;
    }

    &__phone-wrap {
      grid-column: 1/3;
      margin-top: 50px;
    }

    &__email-wrap {
      grid-column: 1/3;
    }

    &__address-wrap {
      grid-column: 4/8;
      grid-row: 1;
      margin-top: 50px;
    }

    &__schedule-wrap {
      grid-column: 4/8;
    }

    &__phone,
    &__email {
      display: block;
      padding: 14px 0;
    }

    &__address,
    &__schedule {
      margin-top: 14px;
    }

    &__map-block {
      margin-top: 60px;
    }

    &__map {
      width: calc(var(--grid-column8) + var(--grid-spacer-and-indent) * 2);
      height: 348px;
    }
  }
}

@include respond-down('medium') {
  .contacts {
    &__phone-title,
    &__email-title,
    &__address-title,
    &__schedule-title {
      font-size: 16px;
    }
  }
}

@include respond-down('small') {
  .contacts {
    &__wrap {
      grid-row-gap: 36px;
    }

    &__phone-wrap {
      grid-column: 1/3;
      margin-top: 40px;
    }

    &__email-wrap {
      grid-column: 1/5;
    }

    &__address-wrap {
      grid-column: 1/5;
    }

    &__schedule-wrap {
      grid-column: 1/5;
    }

    &__phone,
    &__email {
      display: block;
      padding: 14px 0;
    }

    &__address,
    &__schedule {
      margin-top: 14px;
    }

    &__map-block {
      margin-top: 50px;
    }

    &__map {
      width: calc(var(--grid-column4) + var(--grid-spacer-and-indent) * 2);
      height: 414px;
    }
  }
}